/* submit wrapper for Java backend
checks for errors and opens corresponding modals */

import { CacheStorage } from 'cache-storage'
import { openNotification } from 'notifications'

import { openSubmitFailedModal, openSubmitSucceededModal } from 'compositions/modals'


export type JavaResponse = {
  error?: string
  errors?: string[]
  errormsg?: string
  description?: string
  created?: boolean
  updated?: boolean
  deleted?: boolean
  status?: number
  operation?: {
    updated?: boolean
  }
  success?: number
}

export type Result = { data: JavaResponse, cacheStorage: CacheStorage, errors?: string }

type Input = {
  withNotification?: boolean
  job?: () => Promise<Result>
  jobs?: Array<() => Promise<Result>>
  isOpenSubmitSucceeded?: boolean
  failedTitle?: string
  succeededTitle?: string
  succeededText?: string
  succeededRedirectLink?: string
  failedButtonTitle?: string
  failedRedirectLink?: string
  succeededCallback?: (params: Result | Result[]) => void
  failedCallback?: () => void
}

const submitAndCheckForErrors = (props: Input) => {
  const {
    job, jobs, isOpenSubmitSucceeded = true, failedTitle, succeededTitle, succeededText, failedButtonTitle, failedRedirectLink,
    succeededRedirectLink, succeededCallback, withNotification } = props

  return Promise.all(jobs?.map((job) => job()) || [ job() ])
    .then((results) => {
      const netError = results.find(({ errors }) => Boolean(errors))?.errors

      const resultWithBackendError = results.find(({ data }) => {
        const { error, errors, created, updated, deleted, status, operation, success, description, errormsg } = data || {}
        const { updated: operationUpdated } = operation || {}

        return (error
        || errors?.length
        || errormsg
        || created === false
        || updated === false
        || deleted === false
        || operationUpdated === false
        || status < 0
        || success === 0)})

      const backendErrorText = resultWithBackendError?.data?.error
       || resultWithBackendError?.data?.errors?.join(' ')
       || resultWithBackendError?.data?.errormsg
       || resultWithBackendError?.data?.description
        || ''

      if (netError) {
        withNotification
          ? openNotification('error', {
            title: 'Сетевая ошибка',
            text: netError,
          })
          : openSubmitFailedModal({
            title: 'Сетевая ошибка',
            subTitle: netError,
            text: 'Повторите запрос позже',
          })

        return Promise.reject(netError)
      }
      else if (resultWithBackendError) {
        withNotification
          ? openNotification('error', {
            title: failedTitle || 'Ошибка внесения изменений',
            text: backendErrorText,
          })
          : openSubmitFailedModal({
            title: failedTitle || 'Ошибка внесения изменений',
            subTitle: backendErrorText,
            buttonTitle: failedButtonTitle,
            redirectLink: failedRedirectLink,
          })

        return Promise.reject(backendErrorText)
      }
      else if (isOpenSubmitSucceeded) {
        if (withNotification) {
          openNotification('plain', {
            title: succeededTitle || 'Изменения успешно внесены',
            text: succeededText || '',
            icon: 'action/selected_20',
          })

          if (typeof succeededCallback === 'function') {
            succeededCallback(jobs ? results : results?.[0])
          }
        } else {
          openSubmitSucceededModal({
            title: succeededTitle || 'Изменения успешно внесены',
            text: succeededText || '',
            redirectLink: succeededRedirectLink ? succeededRedirectLink : undefined,
            callback: succeededCallback
              ? () => succeededCallback(jobs ? results : results?.[0])
              : undefined,
          })
        }
      }
      else {
        if (typeof succeededCallback === 'function') {
          succeededCallback(jobs ? results : results?.[0])
        }
      }

      return Promise.resolve(jobs ? results : results?.[0].data)
    })
}

export default submitAndCheckForErrors