import { useCallback } from 'react'
import { useMutation } from 'hooks'

import { usePublicModules } from 'models/city'
import config from 'config'


type ApiData = SearchModal.SearchData

const getSearchValue = (search) => search.replace(/\/|\s/g, '+')

const useServiceAreaSearch = () => {
  const { availableModuleTags } = usePublicModules()

  const isServiceAreaAvailable = availableModuleTags.includes('SERVICE_DISTRICTS')
    && config.cityName !== 'Волгодонск' // FIXME temporary fix because of old backend version without search endpoint on Vgd

  const [ submit ] = useMutation<ApiData>({
    url: '/cityProperty/rest/service-districts/company/search',
  })

  const searchServiceArea = useCallback((search: string) => {
    if (!isServiceAreaAvailable) {
      return []
    }

    const searchValue = getSearchValue(search)

    return submit({
      url: `/cityProperty/rest/service-districts/company/search?query=${searchValue}&skip=0&limit=2`,
      method: 'get',
    })
      .then(({ data }) => ([
        {
          ...data,
          items: data.items?.map((item) => ({
            ...item,
            image: `/cityProperty/rest${item.img}`,
          })) || [],
          layer: 'Территории обслуживания УК',
          layerTag: 'SERVICE_DISTRICTS',
        },
      ]))
  }, [ isServiceAreaAvailable, submit ])

  const loadMoreServiceArea = useCallback(({ search, layerId }: { search: string, layerId?: number }) => {
    if (!isServiceAreaAvailable) {
      return Promise.resolve({ items: [] })
    }

    const searchValue = getSearchValue(search)

    return submit({
      url: `/cityProperty/rest/service-districts/company/search?query=${searchValue}&skip=2&limit=20`,
      method: 'get',
    })
      .then(({ data }) => ({
        ...data,
        items: data.items?.map((item) => ({
          ...item,
          image: `/cityProperty/rest${item.img}`,
        })) || [],
        layer: 'Территории обслуживания УК',
        layerTag: 'SERVICE_DISTRICTS',
      }))
  }, [ isServiceAreaAvailable, submit ])

  return {
    searchServiceArea,
    loadMoreServiceArea,
  }
}


export default useServiceAreaSearch
