import React from 'react'
import cx from 'classnames'
import links from 'links'
import { useField } from 'formular'
import { usePathname } from 'router'
import { usePersonalOptions } from 'models/user'

import { ActionButton, ButtonBase } from 'components/inputs'
import { Icon, IconWithText } from 'components/dataDisplay'
import { openConfirmModal } from 'compositions/modals'
import { Tooltip } from 'components/feedback'

import { AddressSelectModal, openAddressSelectModal } from 'layers/profile/pages/HomePage/components'

import s from './Addresses.scss'


type AddressesProps = {
  className?: string
}

const Addresses: React.FC<AddressesProps> = (props) => {
  const { className } = props
  const { personalOptions, isFetching, isSubmitting, addAddress, updateAddress, deleteAddress } = usePersonalOptions()

  const addressField = useField<string>()
  const pathname = usePathname()

  // AddressSelectModal already exist in the profile page
  const isProfilePage = pathname === links.profile

  return (
    <div className={className}>
      {
        personalOptions?.addresses?.map(({ address, lat, lng }, index) => (
          <div
            key={index}
            className={cx('flex', {
              'pb-12px': personalOptions?.addresses[index + 1],
              'pt-12px': index,
              [s.border]: index,
            })}
          >
            <Tooltip
              message={lat && lng ? undefined : 'Координаты не найдены'}
              position="top-left"
            >
              <div className="flex-1">
                <IconWithText
                  message={address}
                  textProps={{
                    size: 't16-20',
                  }}
                  color="titanic"
                  iconName="map/geopoint_16"
                />
              </div>
            </Tooltip>
            <ButtonBase
              className="ml-24px"
              onClick={() => {
                addressField.set(address)

                openAddressSelectModal({
                  title: 'Редактировать адрес',
                  address: addressField,
                  lat,
                  lng,
                  onClose: (props) => {
                    const { address, lat, lng } = props || {}

                    if (address && lat && lng) {
                      updateAddress({ address, lat, lng }, index)
                    }

                    addressField.set('')
                  },
                })
              }}
            >
              <Icon
                name="main/edit_16"
                color="rocky"
              />
            </ButtonBase>
            <ButtonBase
              className="ml-16px"
              onClick={() => openConfirmModal({
                title: `Удалить адрес ${address}?`,
                text: 'Это действие невозможно будет отменить',
                cancelButtonTitle: 'Не удалять',
                confirmButtonTitle: 'Да, удалить',
                onConfirm: () => {
                  deleteAddress(index)
                },
              })}
            >
              <Icon
                name="main/delete_16"
                color="fargo"
              />
            </ButtonBase>
          </div>
        ))
      }
      <ActionButton
        className={cx({
          'mt-24px': personalOptions?.addresses?.length,
        })}
        title="Добавить адрес"
        color="rocky"
        icon="main/add_16"
        disabled={isFetching || isSubmitting}
        onClick={() => openAddressSelectModal({
          title: 'Новый адрес',
          address: addressField,
          onClose: (props) => {
            const { address, lat, lng } = props || {}

            if (address && lat && lng) {
              addAddress({ address, lat, lng })
            }

            addressField.set('')
          },
        })}
      />
      {
        !isProfilePage && (
          <AddressSelectModal />
        )
      }
    </div>
  )
}


export default Addresses
