import React from 'react'
import cx from 'classnames'
import { modalVisibility, ModalVisibilityProps, openModal } from 'modal'

import { Modal } from 'components/feedback'
import { ActionButton } from 'components/inputs'
import { Carousel, Image, Video } from 'components/dataDisplay'

import s from './FullScreenModal.scss'


type FullScreenModalProps = {
  images: string[]
  videos?: string[]
  initialSlide?: number
  isWithLegend?: boolean
  withDots?: boolean
  isImageTrueSize?: boolean
}

const SaveButton = ({ src }) => (
  <div className="mt-8px flex w-full items-center justify-center">
    <ActionButton
      title="Скачать"
      color="rocky"
      size="c13"
      icon="action/load_16"
      href={src}
      download
    />
  </div>
)

const FullScreenModal: React.FC<FullScreenModalProps & ModalVisibilityProps> = (props) => {
  const { initialSlide = 0, images, videos, isWithLegend = true, isImageTrueSize = false, withDownload, withDots, closeModal } = props

  return (
    <Modal
      className={cx(s.modal, 'justify-start')}
      title=""
      closeModal={closeModal}
    >
      <div className="flex justify-center">
        <Carousel
          className={cx(s.carouselRoot, 'radius-16 overflow-hidden')}
          slidesToShow={1}
          withArrows
          withLegend={isWithLegend}
          initialSlide={initialSlide}
          withDots={withDots}
          resizeLock={false}
        >
          {
            images?.map((image, index) => (
              <>
                <Image
                  className={isImageTrueSize ? 'w-full h-full' : null}
                  key={index}
                  src={image}
                  tag={isImageTrueSize ? null : 'div'}
                  width={isImageTrueSize ? null : '774'}
                  height={isImageTrueSize ? null : '774'}
                  withLoader={false}
                  withDownload={withDownload}
                />
                {/* <img className='w-full h-full' src="https://smartsarov.ru/upload/-180/menyu-zavtraka-i-obeda-na-17-12-24-g--0001.jpg" /> */}
                {
                  withDownload && (
                    <SaveButton src={image} />
                  )
                }
              </>
            ))
          }
          {
            videos?.map((video) => (
              <div
                key={video}
                className="relative"
                style={{ width: '774px' }}
              >
                <Video
                  className="w-full h-full"
                  type="mp4"
                  url={video}
                  controls
                />
              </div>
            ))
          }
        </Carousel>
      </div>
    </Modal>
  )
}

export const openFullScreenModal = (props: FullScreenModalProps) => openModal('fullScreenModal', props)


export default modalVisibility('fullScreenModal', FullScreenModal)